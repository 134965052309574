import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../store/root/root.store';
import RobotControlView from '../components/control/multi-robots/robot-control-view';
import { AddRobotToMultiViewComponent } from '../components/control/add-robot-to-multi-view.component';
import { useSolarSubRowsData } from '../hooks/useSolarSubRowsData';
import { INACTIVE_PROMPT_TIMEOUT, MIN_INTERVAL, MAX_INTERVAL } from '../utils/constants';
import ConfirmActiveRgDialog from '../components/dialogs/confirm-active-rg';

export const MultiRobotsPage = observer(() => {
  const { autonomyRobotStore, MultiRobotsStore, chaperonePropertyStore, subrowStore } = useStores();
  const [shouldRobotPaused, setShouldRobotPaused] = useState(false);
  const [shouldPauseAllRobots, setShouldPauseAllRobots] = useState(false);
  const [swEstopAllRobots, setSwEstopAllRobots] = useState(false);
  const { selectedRobots } = autonomyRobotStore;
  const { robots } = autonomyRobotStore;
  const { solarSubRowsData, cords } = useSolarSubRowsData();
  const selectedProperty = useRef(chaperonePropertyStore.getSelectedProperty()?.id);
  const confirmationTimeoutRef = useRef(null);
  const activeCheckTImeRef = useRef(null);
  const [showActiveRGDialogue, setShowActiveRGDialogue] = useState(false);
  const [confirmActiveRG, setConfirmActiveRG] = useState(false);
  const [timeInactive, setTimeInactive] = useState(0);
  const [operationsPaused, setOperationsPaused] = useState(false);
  const { currentPathType } = subrowStore;

  const getSelectedRobots = () => {
    robots.forEach((robot) => {
      const isRobotSelected = selectedRobots.find((robotSN) => robot.serial_number === robotSN);
      if (isRobotSelected) {
        MultiRobotsStore.addRobot({
          id: robot.id,
          serialNumber: robot.serial_number,
          status: robot.status,
          useCase: robot.use_case,
          operatingSubsectionId: robot.operating_subsection_id,
          name: robot.name,
          batteryLevel: robot.battery_level,
          siteName: robot.current_property_name,
          currentRow: robot.operating_subrow_id,
          pathType: currentPathType
        });
      }
    });
  };

  /**
   * Handler to reset all states when RG resumes operations
   */
  const resetEverything = () => {
    setConfirmActiveRG(false);
    setShowActiveRGDialogue(false);
    setTimeInactive(0);
    setShouldPauseAllRobots(false);
    setOperationsPaused(false);
  };

  /** Call back function to reset all states */
  const handleActivation = () => {
    resetEverything();
  };

  /**
   * Handles Active RG Dialogue's Confirm action
   * Function will also ensure all robots are paused.
   * @param {} None
   * @returns {} None
   */
  const handleUserConfirmation = () => {
    setConfirmActiveRG(true);
    setShowActiveRGDialogue(false);
    if (confirmationTimeoutRef.current) {
      clearTimeout(confirmationTimeoutRef.current);
    }
  };

  /**
   * Main entry for dialogue. Handles rendering dialogue and managing associated states
   */
  const checkAndPromptUser = () => {
    if (MultiRobotsStore.hasAutonomousRobotRunning() && !showActiveRGDialogue && MultiRobotsStore.getRobotsArrayLenght() > 0) {
      setShowActiveRGDialogue(true);
      confirmationTimeoutRef.current = setTimeout(() => {
        if (!confirmActiveRG) {
          setShouldPauseAllRobots(true);
          setOperationsPaused(true);
          setTimeInactive(0);
        }
      }, INACTIVE_PROMPT_TIMEOUT * 1000);
    }
  };

  /**
   * Function to set random interval between 2-4 minutes.
   * refactoring here, clearing timeout.
   */
  const setRandomTimeout = () => {
    const randomGeneratedInterval = Math.random() * (MAX_INTERVAL - MIN_INTERVAL) + MIN_INTERVAL;
    if (activeCheckTImeRef.current) {
      clearTimeout(activeCheckTImeRef.current);
    }
    activeCheckTImeRef.current = setTimeout(() => {
      checkAndPromptUser();
      setRandomTimeout();
    }, randomGeneratedInterval);
  };

  /**
   * Prompts RG in the range of 4-6 minutes. Dialog will render randomly within 2-4 minutes range
   */
  useEffect(() => {
    if (!shouldPauseAllRobots) {
      clearInterval(activeCheckTImeRef.current);
      setRandomTimeout();
    }
    // Cleanup function to clear the timeout when dependencies change
    return () => {
      if (confirmationTimeoutRef.current) {
        clearTimeout(confirmationTimeoutRef.current);
      }
    };
  }, [shouldPauseAllRobots, operationsPaused]);

  /**
   * Tracks time elapsed since operations have been paused
   */
  useEffect(() => {
    if (!showActiveRGDialogue) {
      setConfirmActiveRG(false);
    }
    let timer;
    if (showActiveRGDialogue) {
      timer = setInterval(() => {
        setTimeInactive((prev) => prev + 1);
      }, 1000);
    }
    // Cleanup function for the interval
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [showActiveRGDialogue]);

  useEffect(() => {
    getSelectedRobots();
    return () => {
      autonomyRobotStore.resetSelectedRobots();
      MultiRobotsStore.resetRobots();
    };
  }, []);

  return (
    <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
      <ConfirmActiveRgDialog
        title={operationsPaused ? 'All Operations Paused' : 'Confirm to Resume Operations'}
        open={showActiveRGDialogue && !shouldPauseAllRobots}
        action="Confirm"
        key="active-rg"
        handleAction={operationsPaused ? handleActivation : handleUserConfirmation}
        operationsPaused={operationsPaused}
        timeInactive={timeInactive}
      />

      {[...MultiRobotsStore.robots.keys()].map((robotSerialNumber) => (
        <RobotControlView
          key={robotSerialNumber}
          serialNumber={robotSerialNumber}
          shouldRobotPaused={shouldRobotPaused}
          setShouldRobotPaused={setShouldRobotPaused}
          swEstopAllRobots={swEstopAllRobots}
          setSwEstopAllRobots={setSwEstopAllRobots}
          shouldPauseAllRobots={shouldPauseAllRobots}
          setShouldPauseAllRobots={setShouldPauseAllRobots}
          solarSubRowsData={solarSubRowsData}
          cords={cords}
          selectedProperty={selectedProperty.current}
        />
      ))}
      {/* {// TODO REFACTOR} */}
      <AddRobotToMultiViewComponent selectedProperty={selectedProperty.current} />
    </div>
  );
});
