import React, { useCallback } from 'react';
import { withGoogleMap, withScriptjs, GoogleMap, Polyline, Marker } from 'react-google-maps';
import { useSolarMap } from '../../hooks/useSolarMap';

/**
 * SolarGoogleMapComponent - custom google maps react component
 * This component uses google maps react module with custom functions
 * to show robots & subrows on maps. Component also includes logic for
 * updating robot's position, execution progress, and colour coding subrows
 * @param {Object} googleMapData An array of waypoints data
 * @param {Number} robotLat Current robot latitude
 * @param {Number} robotLng Current robot longitude
 * @param {Number} robotHeading Curren robot heading
 * @param {Object} selectedSubRows Currently selected subrows
 * @param {Boolean} resetRobot Resets robot progress
 * @returns
 */
function SolarGoogleMapComponent({ googleMapData, robotLat, robotLng, robotHeading, selectedSubRows, resetRobot }) {
  const { progress, center, setDragged } = useSolarMap({
    mapData: googleMapData,
    // Slightly round lat/lng to reduce tiny changes that cause re-renders.
    robotLat: parseFloat(robotLat),
    robotLng: parseFloat(robotLng),
    resetRobot
  });

  const mapOptions = {
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false
  };

  const icon = useCallback(
    (fillColor, rotation) => ({
      path: 'M 299.5,-0.5 C 299.833,-0.5 300.167,-0.5 300.5,-0.5C 400.455,198.41 500.122,397.41 599.5,596.5C 599.5,597.5 599.5,598.5 599.5,599.5C 598.833,599.5 598.167,599.5 597.5,599.5C 498.256,556.551 398.923,513.551 299.5,470.5C 200.481,513.719 101.481,556.719 2.5,599.5C 1.5,599.5 0.5,599.5 -0.5,599.5C -0.5,598.5 -0.5,597.5 -0.5,596.5C 99.2117,397.41 199.212,198.41 299.5,-0.5 Z',
      fillColor,
      fillOpacity: 1,
      scale: 0.0275,
      anchor: new window.google.maps.Point(300, 300),
      rotation
    }),
    []
  );

  /**
   * This function is responsible for rendering waypoints and starting position.
   * Function also handles logic for colour coding subrows
   */
  const linesAndMarkers = googleMapData.map((line, index) => {
    const lineKey = line?.[0]?.key || `line-${index}`;
    const isCurrentSubRow = line?.[0]?.key && selectedSubRows && selectedSubRows.length > 0 && line[0].key === selectedSubRows[0];
    const isSelectedSubRow = line?.[0]?.key && selectedSubRows && selectedSubRows.includes(line[0].key);
    const strokeColor = isCurrentSubRow ? '#00FF00' : '#00FFFF';
    const transparency = isSelectedSubRow ? 1 : 0;
    const rotation = 90 - (line[0].angle * 180) / Math.PI;

    return (
      <React.Fragment key={lineKey}>
        <Polyline path={line} options={{ strokeColor }} />
        <Marker icon={icon('yellow', rotation)} position={line[0]} opacity={transparency} />
      </React.Fragment>
    );
  });

  // Helper function to render robots progress
  const robotPosition =
    progress && progress.length > 0 ? progress[progress.length - 1] : { lat: parseFloat(robotLat), lng: parseFloat(robotLng) };

  return (
    <GoogleMap
      defaultZoom={18}
      center={center}
      mapTypeId="satellite"
      tilt={0}
      options={mapOptions}
      onDragStart={() => setDragged(true)}
      onDragEnd={() => setDragged(false)}
    >
      {linesAndMarkers}
      {progress && progress.length > 0 && <Polyline path={progress} options={{ strokeColor: '#FF0000', zIndex: 10 }} />}
      <Marker icon={icon('#FF0000', 90 - (robotHeading * 180) / Math.PI)} position={robotPosition} />
    </GoogleMap>
  );
}

const MapComponent = withScriptjs(withGoogleMap(SolarGoogleMapComponent));

const MapComponentExport = ({
  googleMapData,
  robotLat,
  robotLng,
  robotHeading,
  selectedSubRows,
  resetRobot,
  customStyle,
  width = '100%',
  height
}) => (
  <MapComponent
    googleMapData={googleMapData}
    robotLat={robotLat}
    robotLng={robotLng}
    robotHeading={robotHeading}
    selectedSubRows={selectedSubRows}
    resetRobot={resetRobot}
    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
    loadingElement={<div style={{ height: '100%' }} />}
    containerElement={<div style={{ MarginTop: '20px', height, width }} className={customStyle} />}
    mapElement={<div style={{ height: '100%' }} />}
  />
);

export default MapComponentExport;
