/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Grid,
  makeStyles,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  Input,
  Chip,
  Container,
  Tooltip,
  IconButton
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleFilled';
import SettingsIcon from '@material-ui/icons/Settings';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { GpsFixed, GpsNotFixed, GpsOff } from '@material-ui/icons';
import { useStores } from '../../../store/root/root.store';
import RepeatSettingsDialog from '../../dialogs/multi-robots-repeat-settings.dialog';
import ActionsDialog from '../../dialogs/actions.dialog';
import { COMPLETED, IN_PROGRESS, STATUS_MAP, IN_USE, EXEC_SUSPENDED, AVAILABLE, GPS_STATUS_MAP } from '../../../utils/constants';
import { formatStringForReadbility } from '../../../utils/ui.utils';
import { fetchSubBlockById } from '../../../services/api/subblocks.service';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    // paddingRight: theme.spacing(2),
    minWidth: 1600
  },
  grid: {
    backgroundColor: theme.palette.grey[500],
    padding: theme.spacing(1),
    marginBottom: 10,
    borderRadius: theme.spacing(1),
    position: 'absolute',
    top: '1%',
    left: '55px',
    maxWidth: '96%',
    wrap: 'no-wrap'
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold
  },
  PlayCircleOutlineIcon: {
    backgroundColor: '',
    color: 'white',
    height: 35,
    width: 35
  },
  connectionIndicatorIcon: {
    height: 40,
    width: 45
  },
  toggleGroup: {
    backgroundColor: theme.palette.grey[200],
    Width: '100%',
    height: 35
  },
  ToggleButton: {
    padding: theme.spacing(1),
    fontWeight: 'bold'
  },
  Button: {
    marginLeft: theme.spacing(1),
    height: 35,
    width: 176,
    backgroundColor: '#FF0000'
  },
  Stepper: {
    backgroundColor: theme.palette.grey[500],
    padding: theme.spacing(1)
  },
  Notifications: {
    backgroundColor: theme.palette.grey[100],
    fontSize: '1.5em',
    width: '100%',
    height: '75%',
    borderRadius: 4,
    marginRight: theme.spacing(1),
    overflow: 'auto'
  },
  Notes: {
    backgroundColor: theme.palette.grey[100],
    width: '100%',
    height: '75%',
    borderRadius: 4,
    marginRight: theme.spacing(1)
  },
  formControl: {
    width: '100%',
    marginRight: theme.spacing(1)
  },
  solarSubRows: {
    width: '100%',
    marginRight: theme.spacing(1)
  },
  RestartButtton: {
    padding: '5px 16px'
  },
  ButtonGroup: {
    height: '63px'
  },
  select: {
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(1),
    height: '63px',
    borderRadius: '4px'
  },
  chips: {
    display: 'flex'
  },
  chip: {
    margin: 2
  },
  multiSelect: {
    backgroundColor: theme.palette.inverted.main,
    padding: theme.spacing(1)
  },
  selected: {
    backgroundColor: 'gray !important',
    color: 'white'
  },
  disabledCursor: {
    cursor: 'not-allowed'
  },
  selectedSolarRow: {
    padding: theme.spacing(2),
    backgroundColor: '#fff',
    borderRadius: '5px'
  },
  completedSolarRow: {
    backgroundColor: '#2eb885',
    color: '#fff'
  },
  inProgressSolarRow: {
    backgroundColor: '#03a9f4',
    color: '#fff'
  },
  restrictedSolarRow: {
    backgroundColor: '#ed271c',
    color: '#fff'
  },
  keepOutRow: {
    backgroundColor: '#170106',
    color: '#fff'
  },
  gpsStatusIndicatorBaseClass: {
    height: 40,
    width: 45
  },
  gpsStatusFixedRTK: {
    color: '#63f800'
  },
  gpsStatusFloatRTK: {
    color: '#ffc61b'
  },
  gpsError: {
    color: 'red'
  }
}));

const MultiRobotsRepeatControls = withRouter(
  observer(
    ({
      readOnlyMode,
      localDataStore,
      changeRepeatingState,
      changeDriveState,
      cancelRepeatingTask,
      isSolarAuditOfficer,
      controlMode,
      solarSubRows,
      robot,
      region,
      wpsState,
      property,
      block,
      subBlock,
      pathType,
      serialNumber,
      linearSpeed,
      lookAheadDistance,
      angularSpeed,
      videoGamma,
      videoContrast,
      interRowDirection,
      isTeleopsStreamingEnabled,
      connectionStatusMessageColor,
      setShowRestartRobotDialog,
      setShowRestartVideoDialog,
      isVideoStreamingRefreshed,
      setIsVideoStreamingRefreshed,
      setShowSubRowsTable,
      showSubRowsTable,
      updateSolarSubRows,
      robotGpsState,
      robotInsState,
      robotInsStatus,
      isAutonomyAndTeleopsDisabled
    }) => {
      const { regionsStore, chaperonePropertyStore, blocksStore, subBlocksStore, autonomyRobotStore, MultiRobotsStore } = useStores();
      const classes = useStyles();
      const [showRepeatSettingsDialog, setShowRepeatSettingsDialog] = useState(false);
      const [openDialog, setOpenDialog] = useState(false);
      const [selectControlMode, setSelectControlMode] = useState('');
      const gpsInsStatus = useRef(null);
      const regionName = regionsStore.getById(region)?.name;
      const propertyName = chaperonePropertyStore.getById(property)?.name;
      const blockName = blocksStore.getById(block)?.name;
      const [selectedSubBlockName, setSelectedSubBlockName] = useState(subBlocksStore.getById(subBlock)?.name || '');
      const currentRobot = autonomyRobotStore.getById(robot);
      const selectedSolarSubRows = MultiRobotsStore.robots.get(serialNumber)?.selectedSolarSubRows;
      const notificationMessage = MultiRobotsStore.robots.get(serialNumber)?.notificationMessage || 'No New Notifications';
      const linearVelocityKmH = MultiRobotsStore.robots.get(serialNumber)?.estopState
        ? 0
        : parseFloat(((MultiRobotsStore.robots.get(serialNumber)?.linearVelocity * 18) / 5).toFixed(1)) || '0';
      const confirmWarningDialog = () => {
        changeDriveState(selectControlMode);
        setOpenDialog(false);
      };
      const closeDialogue = () => setOpenDialog(false);

      const handleChangeToggleButton = (_, toggleControlMode) => {
        if (toggleControlMode !== null) {
          if (STATUS_MAP[Number(wpsState)] === EXEC_SUSPENDED && toggleControlMode === 'autonomous') {
            setOpenDialog(true);
            setSelectControlMode(toggleControlMode);
          } else {
            changeDriveState(toggleControlMode);
          }
        }
      };

      const handleChangeSolarSubRows = (event) => {
        MultiRobotsStore.setSelectedSolarSubRows(serialNumber, event.target.value);
      };

      const closeSettingDialog = () => {
        setShowRepeatSettingsDialog(false);
      };

      const getStatusClass = (subrow) => {
        if (subrow.is_keep_out_zone) return classes.keepOutRow;
        if (subrow.is_restricted === true) return classes.restrictedSolarRow;
        if (subrow.status === COMPLETED) return classes.completedSolarRow;
        if (subrow.status === IN_PROGRESS) return classes.inProgressSolarRow;
        return '';
      };

      useEffect(() => {
        if (showRepeatSettingsDialog && STATUS_MAP[wpsState] === IN_USE) {
          changeRepeatingState();
        }
      }, [showRepeatSettingsDialog, wpsState]);

      useEffect(() => {
        updateSolarSubRows();
      }, [showSubRowsTable]);

      useEffect(() => {
        const setSubBlockName = async () => {
          const result = await fetchSubBlockById(subBlock);
          setSelectedSubBlockName(result?.results?.name);
        };
        setSubBlockName();
      }, [subBlock]);

      // Handles setting and rendering GPS & INS indicators
      const setGpsStatusIcon = () => {
        const iconTitle =
          robotGpsState === GPS_STATUS_MAP.FIXED_RTK || robotGpsState === GPS_STATUS_MAP.FLOAT_RTK
            ? `${robotGpsState} -- ${robotInsStatus}`.toString()
            : `${robotGpsState}`.toString();
        if (gpsInsStatus.current !== iconTitle) {
          gpsInsStatus.current = iconTitle.toString();
        }
        return (
          <Tooltip>
            {
              // eslint-disable-next-line no-nested-ternary
              robotGpsState === GPS_STATUS_MAP.FIXED_RTK ? (
                <GpsFixed
                  className={`${classes.gpsStatusIndicatorBaseClass} ${
                    robotInsState ? classes.gpsStatusFixedRTK : classes.gpsStatusFloatRTK
                  }`}
                />
              ) : robotGpsState === GPS_STATUS_MAP.FLOAT_RTK ? (
                <GpsNotFixed className={`${classes.gpsStatusIndicatorBaseClass} ${classes.gpsStatusFloatRTK} `} />
              ) : (
                <GpsOff className={`${classes.gpsStatusIndicatorBaseClass} ${classes.gpsError} `} />
              )
            }
          </Tooltip>
        );
      };

      useEffect(() => {
        setGpsStatusIcon();
      }, [robotGpsState]);

      return (
        <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" className={classes.grid} xs={12}>
          <ActionsDialog
            dialogTitle="Robot is not in autonomous mode now, do you want it in autonomous?"
            open={openDialog}
            actions={[
              { color: '#ed5063', name: 'Confirm', handler: confirmWarningDialog },
              { color: '#555555', name: 'Cancel', handler: closeDialogue }
            ]}
          />
          {showRepeatSettingsDialog && (
            <RepeatSettingsDialog
              readOnlyMode={readOnlyMode}
              handleClose={closeSettingDialog}
              serialNumber={serialNumber}
              linearSpeed={linearSpeed}
              lookAheadDistance={lookAheadDistance}
              angularSpeed={angularSpeed}
              videoGamma={videoGamma}
              videoContrast={videoContrast}
              interRowDirection={interRowDirection}
            />
          )}
          <Grid item container direction="column" justifyContent="flex-start" alignItems="flex-start" xs={2}>
            <Typography>
              Serial Number:{' '}
              <b>{formatStringForReadbility(currentRobot?.serial_number) || formatStringForReadbility(localDataStore?.serial_number)}</b>
            </Typography>
            <Typography>
              Site: <b>{formatStringForReadbility(localDataStore?.property_name) || formatStringForReadbility(propertyName)}</b>
            </Typography>
            <Typography>
              Block: <b>{formatStringForReadbility(localDataStore?.block_name) || formatStringForReadbility(blockName)}</b>
            </Typography>
            <Typography>
              Sub Block: <b>{formatStringForReadbility(selectedSubBlockName)}</b>
            </Typography>
          </Grid>
          <Grid item container direction="column" justifyContent="flex-start" alignItems="flex-start" xs={2}>
            <Typography>
              Region: <b>{formatStringForReadbility(localDataStore?.region_name) || formatStringForReadbility(regionName)}</b>
            </Typography>
            <Typography>
              {`Speed (Km/H): `}
              <strong>{linearVelocityKmH}</strong>
            </Typography>
            <Typography>
              Inter-row: <b>{formatStringForReadbility(interRowDirection === 'l-r' ? 'FORWARD' : 'REVERSE') || ''}</b>
            </Typography>
            <Typography>
              Path Type: <b>{formatStringForReadbility(pathType) || ''}</b>
            </Typography>
          </Grid>
          <Grid item container direction="row" justifyContent="flex-start" alignItems="flex-start" xs={3} style={{ maxHeight: '90px' }}>
            <Typography>Notifications:</Typography>
            <Container className={classes.Notifications} justifycontent="center">
              {notificationMessage}
            </Container>
          </Grid>
          {solarSubRows && (
            <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs={1} style={{ display: 'flex' }}>
              <FormControl className={classes.solarSubRows}>
                {isSolarAuditOfficer ? <Typography>Selected Solar SubRows:</Typography> : <Typography>Select Solar SubRows:</Typography>}
                {isSolarAuditOfficer ? (
                  <div className={classes.selectedSolarRow}>
                    <Typography>{selectedSolarSubRows}</Typography>
                  </div>
                ) : (
                  <Select
                    multiple
                    disabled={readOnlyMode}
                    value={selectedSolarSubRows}
                    className={classes.multiSelect}
                    style={{}}
                    onChange={handleChangeSolarSubRows}
                    input={<Input />}
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {selected.map((value) => (
                          <Chip key={value} label={value.substring(0, value.indexOf('__'))} className={classes.chip} />
                        ))}
                      </div>
                    )}
                  >
                    {solarSubRows.map((subrow) => (
                      <MenuItem
                        selected
                        disabled={subrow.status === IN_PROGRESS || subrow.is_restricted === true}
                        classes={{ selected: subrow.status !== IN_PROGRESS ? classes.selected : getStatusClass(IN_PROGRESS) }}
                        className={getStatusClass(subrow)}
                        key={subrow.id}
                        value={subrow.value}
                      >
                        {subrow.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
            </Grid>
          )}

          <Grid item container direction="row" justifyContent="flex-start" alignItems="flex-start" xs={1}>
            <Typography>Restart:</Typography>
            <FormControl className={classes.formControl}>
              <ButtonGroup
                orientation="vertical"
                aria-label="vertical outlined button group"
                variant="contained"
                className={classes.ButtonGroup}
              >
                <Button
                  key="resetController"
                  disabled={readOnlyMode}
                  onClick={() => setShowRestartRobotDialog(true)}
                  className={classes.RestartButtton}
                >
                  Software
                </Button>
                <Button key="resetVideo" onClick={() => setShowRestartVideoDialog(true)} className={classes.RestartButtton}>
                  Video
                </Button>
              </ButtonGroup>
            </FormControl>
          </Grid>
          <Grid item container direction="row" justifyContent="flex-start" alignItems="flex-start" xs={3}>
            <Grid item container direction="row" justifyContent="flex-end" alignItems="center" style={{ marginRight: '50px' }}>
              <Tooltip title={!showSubRowsTable ? 'Show Solar Subrow Table' : 'Hide Solar Subrow Table'}>
                <IconButton className={classes.PlayCircleOutlineIcon}>
                  <ListAltIcon
                    className={classes.PlayCircleOutlineIcon}
                    color="action"
                    disabled={readOnlyMode}
                    onClick={() => {
                      setShowSubRowsTable(!showSubRowsTable);
                    }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="Refresh Video Streaming">
                <IconButton className={classes.PlayCircleOutlineIcon}>
                  <RefreshIcon
                    className={classes.PlayCircleOutlineIcon}
                    color="action"
                    disabled={readOnlyMode}
                    onClick={() => {
                      setIsVideoStreamingRefreshed(!isVideoStreamingRefreshed);
                    }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title={connectionStatusMessageColor.message}>
                <FiberManualRecordIcon
                  style={{ color: `${connectionStatusMessageColor.color}` }}
                  className={classes.connectionIndicatorIcon}
                />
              </Tooltip>
              <Tooltip title={gpsInsStatus.current}>{setGpsStatusIcon()}</Tooltip>
              <SettingsIcon
                className={classes.PlayCircleOutlineIcon}
                color="action"
                disabled={readOnlyMode}
                onClick={() => {
                  setShowRepeatSettingsDialog(true);
                }}
              />
              {selectedSolarSubRows.length > 0 && (
                <>
                  <PlayCircleOutlineIcon
                    className={classes.PlayCircleOutlineIcon}
                    color="action"
                    style={{
                      display: STATUS_MAP[wpsState] !== IN_USE ? 'inline' : 'none',
                      // Use a ternary operator to set the color to gray when disabled
                      color: isAutonomyAndTeleopsDisabled ? 'grey' : 'white'
                    }}
                    onClick={() => {
                      if (!isAutonomyAndTeleopsDisabled) {
                        changeRepeatingState();
                      }
                    }}
                  />
                  <PauseCircleOutlineIcon
                    className={classes.PlayCircleOutlineIcon}
                    color="action"
                    style={{
                      display: STATUS_MAP[wpsState] === IN_USE ? 'inline' : 'none'
                    }}
                    onClick={() => changeRepeatingState()}
                  />
                  <Button disabled={readOnlyMode} onClick={() => cancelRepeatingTask(false)} className={classes.Button} variant="contained">
                    <b> Cancel Path </b>
                  </Button>
                </>
              )}
            </Grid>
            <Grid item container direction="row" justifyContent="flex-end" alignItems="center" style={{ marginRight: '50px' }}>
              <ToggleButtonGroup
                className={classes.toggleGroup}
                exclusive
                value={controlMode}
                onChange={handleChangeToggleButton}
                aria-label="text formatting"
              >
                <ToggleButton disabled={readOnlyMode} color="primary" value="manual" aria-label="bold" className={classes.ToggleButton}>
                  Manual
                </ToggleButton>
                <ToggleButton
                  disabled={readOnlyMode || !isTeleopsStreamingEnabled}
                  color="secondary"
                  value="teleops"
                  aria-label="italic"
                  className={classes.ToggleButton}
                >
                  TeleOps
                </ToggleButton>
                {STATUS_MAP[wpsState] !== AVAILABLE && (
                  <ToggleButton
                    disabled={readOnlyMode}
                    color="secondary"
                    value="autonomous"
                    aria-label="italic"
                    className={classes.ToggleButton}
                  >
                    Autonomous
                  </ToggleButton>
                )}
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        </Grid>
      );
    }
  )
);

export default MultiRobotsRepeatControls;
